<template>
  <div class="allWrap d-flex flex-column h-100">
    <div class="mainContent flex-shrink-0">
      <div class="navigation w-100 pt-3 pb-3">
        <div class="container">
          <div class="row">
            <div class="col-8 col-lg-5">
              <div class="logo">
                <a href="/" class="text-decoration-none h-100">
                  <img src="@/assets/dfb-akademie-logo.svg" class="rounded-circle img-fluid">
                  <span class="d-inline-block ms-3 fw-bold">

                DFB-Akademie <span class="d-none d-lg-inline-block">Bewerbungsportal</span>

              </span>
                </a>
              </div>
            </div>
            <div class="col-12 col-lg-7">
              <nav class="navbar">
                <ul class="nav mainNav justify-content-end" :class="(showNav) ? '' : 'd-none' ">
                  <li class="d-inline-block pt-2"><a href="#" class="text-white text-decoration-none"><i
                    class="bi bi-person"></i></a></li>
                  <li class="nav-item" v-if="checkLogin">
                    <router-link :to="{ name: 'dashboard' }" class="nav-link" @click="showNav = false">
                      <!--<font-awesome-icon :icon="['far', 'user']"/>-->
                      <span><i class="fa-home fa-regular"></i></span>
                    </router-link>
                  </li>
                  <li class="nav-item" v-if="checkLogin">
                    <router-link :to="{ name: 'applications' }" class="nav-link" @click="showNav = false">
                      <!--<font-awesome-icon :icon="['far', 'folder-open']"/>-->
                      <span>Bewerbungen</span>
                    </router-link>
                  </li>
                  <li class="nav-item" v-if="checkLogin">
                    <router-link :to="{ name: 'profile' }" class="nav-link" @click="showNav = false">
                      <!--<font-awesome-icon :icon="['far', 'user']"/>-->
                      <span>Profil</span>
                    </router-link>
                  </li>
                  <li class="nav-item" v-if="checkLogin && $store.state.useIssuesFrontend === '1'">
                    <router-link :to="{ name: 'Issues' }" class="nav-link" @click="showNav = false">
                      <!--<font-awesome-icon :icon="['far', 'user']"/>-->
                      <span>Tickets</span>
                    </router-link>
                  </li>
                  <li class="nav-item">
                    <router-link :to="{ name: 'voraussetzungen' }" class="nav-link" @click="showNav = false">
                      <!--<font-awesome-icon :icon="['far', 'user']"/>-->
                      <span v-if="checkLogin" class="restitle">
                      <!-- font awesome icon checklist -->
                      Zulassungs&shy;voraussetzungen
                    </span>
                      <span style="position: relative;
top: 6px;" v-else>Zulassungs&shy;voraussetzungen</span>
                    </router-link>
                  </li>
                  <li class="nav-item" v-if="checkLogin"><span class="nav-link logout-button" @click="logoutInline();"
                                                               title="Abmelden"><i class="fa-regular fa-power-off"></i></span>
                  </li>
                </ul>
              </nav>
              <button class="showNavButton btn btn-primary btn-sm float-end" type="button" @click="toggleNav">
                <font-awesome-icon :icon="['far', 'bars']" size="1x"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <router-view></router-view>
    </div>
    <footer class="footer mt-auto pt-4 pb-4 mt-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <ul class="list-style-none p-0 m-0 text-center">
              <li class="d-inline-block me-3">Copyright © 2024 DFB GmbH & Co. KG</li>
              <li class="d-inline-block me-3">
                <a href="https://www.dfb-akademie.de/imprint" target="_blank" class="text-decoration-none">
                  <!--<font-awesome-icon :icon="['far', 'user']"/>-->
                  <span class=" d-lg-inline-block ms-2">Impressum</span>
                </a>
              </li>
              <li class="d-inline-block me-3"><a target="_blank" href="https://www.dfb.de/datenschutzerklaerung/"
                                                 class="text-decoration-none">Datenschutz</a></li>
              <!--              <li class="d-inline-block"><a href="#" class="text-decoration-none">Kontakt</a></li>-->
            </ul>
            <p style="font-size: 10px"> Version {{ $store.state.version }}</p>
          </div>
        </div>
      </div>
    </footer>
    <BootstrapToast/>
    <div class="newVersionTest" @click="$store.state.publishNewVersion = false;"
         v-if="$store.state.publishNewVersion">
      <div class="VersionWarningWrap">
        <h2 class="mb-3" style='font-family: "DFB Stencil"'>Neue Version verfügbar!</h2>
        <a href="/" class="button btn btn-success mb-3">Hier klicken!</a>
        <p class="versionInfo">Version: {{ $store.state.version }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import environment from "./auth/environment"
import BootstrapToast from './components/BootstrapToast'
import router from "@/router";
import fontAwesomeIcon from "@/components/FontAwesome";
import axios from "axios";

export default {
  name: 'App',
  computed: {
    ...mapGetters(['checkLogin', 'getUser'])
  },
  components: {
    BootstrapToast, fontAwesomeIcon
  },
  methods: {
    ...mapActions(['logout', "checkToken"]),
    toggleNav() {
      if (this.showNav === false) {
        this.showNav = true;
      } else {
        this.showNav = false;
      }
    },
    getIssueFrontend() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let getUrl = this.$store.getters.getApiUrl + "config/params/values/issues_active_frontend";
      axios.get(getUrl, {headers}).then(async (response) => {
        let shortResponse = response.data.result;
        this.$store.commit('setUseIssuesFrontend', shortResponse.data);
      }).catch(function () {
      });
    },
    logoutInline() {
      localStorage.removeItem('dfbToken');
      this.$store.state.loggedInState = null
      this.$store.state.scope = {scope: null, write: false, loggedOut: true}
      this.$store.state.acl = null
      this.$store.state.user = null
      this.$store.state.currentProgress = 1
      this.$store.state.currentApplication = null
      this.$store.state.user = null
      router.push('/');
      window.location.href = environment.logoutUrlComplete;
    },
  },
  created() {
    this.checkToken();
  },
  data() {
    return {
      showNav: false,
      preferences: [
        {
          title: 'Systemrelevante Cookies',
          description: 'Diese Cookies sind für die Funktion der Website notwendig und können nicht deaktiviert werden.',
          items: [{label: 'technische Cookies', value: 'technical', isRequired: true}],
        },
      ]
    }
  },
  watch: {
    '$route'() {
      this.getIssueFrontend();
    },
    '$route.params.token': {
      async handler(newToken) {
        if (newToken) {
          // logout current user first
          // Store the token in LocalStorage
          localStorage.setItem('dfbToken', newToken);

          // Wait for token verification
          await this.checkToken();


          // Ensure user is set before redirecting
          const user = this.getUser.id;
          if (user === 0 || user === null || user === undefined || !user) {
            setTimeout(() => {
              window.location.reload();
            }, 500);
            return;
          }

          // Navigate to dashboard (or stored return URL)
          this.$router.push('/');
        }
      },
      immediate: true
    }


  },
}
</script>
<style type="scss">
button.showNavButton.d-inline-block.d-lg-none.btn.btn-primary.btn-sm.float-end {
  position: absolute;
  right: 12px;
  top: 23px;
}

.newVersionTest {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  top: 0;
  background: #ffffffe0;
  padding: 20px;
  text-align: center;
  padding-top: 10vh;
}

.ps {
  height: 60vh;
}

.file-uploads.btn {
  padding: 2px !important;
}

.logout-button {
  cursor: pointer;
}
</style>
