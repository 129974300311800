<template>
  <div class="example-avatar">
    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>Drop files to upload</h3>
    </div>
    <div class="avatar-upload" v-show="!edit">
      <div class="text-center p-2">
        <label for="avatar">
          <img
            :src="profileImage ? 'data:image/png;base64,'+profileImage : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'"
            class="rounded-circle"/>

          <p class="text-sm">Das Gesicht sollte komplett sichtbar sein. Keine Mannschaftsfotos verwenden. Min. Auslösung: 300x200 </p>
        </label>
      </div>
      <div class="text-center p-2">
        <file-upload
          extensions="gif,jpg,jpeg,png,webp"
          accept="image/png,image/gif,image/jpeg,image/webp"
          name="avatar"
          class="btn btn-primary"
          :headers='header'
          :post-action="postAction"
          :drop="!edit"
          v-model="files"
          @input-filter="inputFilter"
          @input-file="inputFile"
          ref="upload">
          <span style="cursor:pointer; display: inline-block;padding: 4px;"><i class="fa-regular fa-image"></i> Bild hochladen</span>
        </file-upload>
        <span tooltip="Avatar löschen?" @click="deleteAvatar"
              style="cursor:pointer; display: inline-block;padding: 4px; margin-left: 12px;"><i
          class="fa-regular fa-trash"></i></span>
      </div>
    </div>
    <div class="avatar-edit" v-show="files.length && edit">
      <div class="avatar-edit-image" v-if="files.length">
        <img ref="editImage" :src="files[0].url"/>
      </div>
      <div class="text-center p-4">
        <button type="button" class="btn btn-secondary" @click.prevent="$refs.upload.clear"><i
          class="fa-regular fa-x"></i></button>
        <button type="submit" class="btn btn-primary" @click.prevent="editSave"><i
          class="fa-regular fa-floppy-disk"></i></button>
      </div>
    </div>
  </div>
</template>
<style>
.example-avatar .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
}

.example-avatar .text-center .btn {
  margin: 0 .5rem
}

.avatar-edit-image {
  max-width: 100%;
  max-height: 500px;
}

.example-avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}

.example-avatar .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
<script>
import Cropper from 'cropperjs'
import ImageCompressor from '@xkeshi/image-compressor';
import FileUpload from 'vue-upload-component'
import {mapGetters} from "vuex";
import {useToast} from 'vue-toastification'
import axios from "axios";

export default {
  components: {
    FileUpload,
    ImageCompression: ImageCompressor
  },
  props: {
    profileImage: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      files: [],
      edit: false,
      cropper: false,
      postAction: '',
      header: {"crmAuth": localStorage.getItem("dfbToken")}
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 2 / 3,
            viewMode: 1,
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  computed: {
    ...mapGetters(["getUser", "getApiUrl"])
  },
  methods: {
    compressImage(file, quality = 0.6) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            let width = img.width;
            let height = img.height;

            // Full HD resolution
            const maxWidth = 1080;
            const maxHeight = 1920;

            if (width > maxWidth) {
              height = (height * maxWidth) / width;
              width = maxWidth;
            }

            if (height > maxHeight) {
              width = (width * maxHeight) / height;
              height = maxHeight;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(blob => {
              new ImageCompressor(blob, {
                quality,
                success(result) {
                  resolve(result);
                },
                error(e) {
                  reject(e);
                },
              });
            }, 'image/jpeg', quality);
          };
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },
    setCustomer() {
      this.postAction = this.getApiUrl + 'customer/' + this.getUser.crm_customer_id + '/avatar'
    },
    deleteAvatar() {
      const headers = {"crmAuth": localStorage.getItem("dfbToken")};
      let VueInner = this;
      const toast = useToast();
      axios.put(this.getApiUrl + 'customer/' + this.getUser.crm_customer_id, {
        profile_image: '',
        profile_mimetype: ''
      }, {headers}).then(function (response) {
        let shortReport = response.data.result;
        if (shortReport.success === true) {
          const triggerReloadTask = new CustomEvent('triggerReloadProfile');
          window.dispatchEvent(triggerReloadTask);
          toast.success('Avatar gelöscht');
        } else {
          toast.error('Avatar konnte nicht gelöscht werden');
        }
      }).catch(function (error) {
        console.log(error);
      });
    },
    async editSave() {
      this.edit = false;
      let oldFile = this.files[0];
      let binStr = atob(this.cropper.getCroppedCanvas().toDataURL(oldFile.type).split(',')[1]);
      let arr = new Uint8Array(binStr.length);
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i);
      }
      let file = new File([arr], oldFile.name, {type: oldFile.type});

      // Compress the cropped file before upload
      try {
        const compressedFile = await this.compressImage(file);
        file = compressedFile;
      } catch (error) {
        console.error('Failed to compress image', error);
      }

      // Update the file in the component's state and proceed with the upload
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true,
      });
    },
    alert(message) {
      alert(message)
    },
    async inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        const triggerReloadTask = new CustomEvent('fileAddedInput');
        window.dispatchEvent(triggerReloadTask);

        let img = new Image();
        img.onload = () => {
          if (img.width < 200 || img.height < 300) {
            const index = this.files.findIndex(file => file.id === newFile.id);
            if (index > -1) {
              this.files.splice(index, 1);
            }
            this.alert('Die Bildabmessungen sollten mindestens 300x200px betragen.');
          } else {
            this.$nextTick(function () {
              this.edit = true;
            });
          }
        };
        img.src = newFile.url;
      }
      if (newFile && oldFile) {
        if (newFile.active === false) {
          this.edit = false;
          const toast = useToast();
          toast.success('Bild wurde erfolgreich hochgeladen.');
          const triggerReloadTask = new CustomEvent('triggerReloadProfile');
          window.dispatchEvent(triggerReloadTask);
        }
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Nur Bilder sind erlaubt');
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    },
  },
  created: function () {
    this.setCustomer()
  },
}
</script>
