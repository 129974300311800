<template>

 <MainHeader headline="Dashboard" text="Bewerbungsportal der DFB-Akademie" />

  <div class="dashboard2 pb-20 pt-5">

    <div class="container">

      <div class="row">
        <div class="col-lg-4 d-flex align-items-stretch mb-4">

          <router-link :to="{ name: 'profile' }" role="button" class="text-decoration-none h-100 w-100">

            <div class="card h-100 w-100 flex-fill">

              <div class="card-body p-4 d-flex flex-wrap">

                <div class="row w-100">

                  <div class="col-10">

                    <h4 class="fw-bold">Mein Profil</h4>

                  </div>

                  <div class="col-2">

                    <font-awesome-icon :icon="['far', 'user']" size="2x" />


                  </div>

                </div>

                <div class="row w-100 align-self-end w-100">

                  <div class="col-12">

                  <span class="d-inline-block">
                    <font-awesome-icon :icon="['far', 'arrow-right']" />
                  </span>

                    <div class="d-inline-block text-uppercase text-secondary ms-3">
                      Öffnen
                    </div>

                  </div>

                </div>


              </div>

            </div>

          </router-link>

        </div>
        <div class="col-lg-4 d-flex align-items-stretch mb-4">

          <router-link :to="{ name: 'applications' }" role="button" class="text-decoration-none w-100">

            <div class="card flex-fill h-100 w-100">

              <div class="card-body p-4 d-flex flex-wrap">

                <div class="row w-100">

                  <div class="col-10">

                    <h4 class="fw-bold">Meine Bewerbungen</h4>

                  </div>

                  <div class="col-2">

                    <font-awesome-icon :icon="['far', 'folder-open']" size="2x" />


                  </div>

                </div>

                <div class="row  w-100 align-self-end w-100">

                  <div class="col-12">

                  <span class="d-inline-block">
                    <font-awesome-icon :icon="['far', 'arrow-right']" />
                  </span>

                    <div class="d-inline-block text-uppercase text-secondary ms-3">
                      Öffnen
                    </div>

                  </div>

                </div>


              </div>

            </div>

          </router-link>

        </div>



        <div class="col-lg-4 d-flex align-items-stretch mb-4">
          <router-link :to="{ name: 'voraussetzungen' }" role="button" class="text-decoration-none w-100">
          <div class="card text-decoration-none h-100 w-100 flex-fill" role="button" >

            <div class="card-body p-4 d-flex flex-wrap">

              <div class="row w-100">

                <div class="col-10">

                  <h4 class="fw-bold">Zulassungs&shy;voraussetzungen</h4>

                </div>

                <div class="col-2">

                  <font-awesome-icon :icon="['far', 'shield-exclamation']" size="2x" />


                </div>

              </div>

              <div class="row w-100 align-self-end w-100">

                <div class="col-12">

                <span class="d-inline-block">
                    <font-awesome-icon :icon="['far', 'arrow-right']" />
                  </span>

                  <div class="d-inline-block text-uppercase text-secondary ms-3">
                    Öffnen
                  </div>

                </div>

              </div>


            </div>

          </div>
          </router-link>

        </div>

      </div>

    </div>

  </div>

</template>

<script>

import MainHeader from "@/components/MainHeader";
import {mapActions}  from "vuex";
import environment from "@/auth/environment"
import router from "@/router";
export default {

  name: 'DashboardView',
  components: {MainHeader},
  methods: {
    ...mapActions(["logout"]),
    logoutInline() {
      localStorage.removeItem('dfbToken');
      this.$store.state.loggedInState = null
      this.$store.state.scope = { scope: null, write: false, loggedOut: true }
      this.$store.state.acl = null
      this.$store.state.user = null
      this.$store.state.currentProgress = 1
      this.$store.state.currentApplication = null
      this.$store.state.user = null
      router.push('/');
      window.location.href = environment.logoutUrlComplete;
    }
  }
}

</script>
<style lang="scss">
.card{
  min-height: 200px;
}
</style>
