<template>
<div class="card p-1 p-lg-4">
  <div class="card-body">
    <button class="btn btn-primary float-end" @click="showAddElement = true"><i class="fa-solid fa-plus-circle"></i> Eintrag</button>
    <h4>Lebenslauf</h4>
  </div>
</div>
  <Modal v-if="showAddElement">
    <div class="modal show" style="display: block;">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
Eintrag hinzufügen
            <button type="button" class="btn-close" @click="showAddElement = false"></button>

          </div>
          <div class="modal-body">


          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
<script>
import {mapGetters} from "vuex";


export default {
  name: "ResumeView",
  data: () => ({
    showAddElement: false,
    file: {
      title: 'Neuer Eintrag'
    }
  }),
  computed: {
    ...mapGetters(["getUser"])
  },
  methods: {
    addElement() {
      console.log('addElement')
    }
  }
}
</script>
